import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, Card, CardContent, Divider, Chip } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import indexedDBService from '../services/indexedDBService';
import { useNavigate } from 'react-router-dom';
import { 
  CalendarMonth, 
  TrendingUp, 
  EmojiEvents,
  Palette
} from '@mui/icons-material';

const designRankColors = {
  0: {
    bg: '#FFD700',
    hover: '#FFC300',
    text: '#000000'
  },
  1: {
    bg: '#C0C0C0',
    hover: '#A6A6A6',
    text: '#000000'
  },
  2: {
    bg: '#CD7F32',
    hover: '#B87333',
    text: '#FFFFFF'
  },
  3: {
    bg: '#1fb7d7',
    hover: '#048ad2',
    text: '#FFFFFF'
  },
  4: {
    bg: '#5cb461',
    hover: '#45a248',
    text: '#FFFFFF'
  }
};

const Dashboard = () => {
  const navigate = useNavigate();

  const [dateRange, setDateRange] = useState(() => ({ 
    start: new Date(localStorage.getItem('globalStartDate') || new Date().setFullYear(new Date().getFullYear() - 1)), 
    end: new Date(localStorage.getItem('globalEndDate') || new Date()) 
  }));

  const [summaryData, setSummaryData] = useState({
    totalRevenue: 0,
    grossProfit: 0,
    activeLocations: 0,
    totalBookings: 0,
  });

  const [revenueByIndustry, setRevenueByIndustry] = useState([]);
  const [topClientsByRevenue, setTopClientsByRevenue] = useState([]);
  const [topLocationPerformance, setTopLocationPerformance] = useState([]);
  const [predictedBookings, setPredictedBookings] = useState([]);
  const [popularDesigns, setPopularDesigns] = useState({ monthly: [], yearly: [] });

  useEffect(() => {
    loadDashboardData(dateRange.start, dateRange.end);

    const handleGlobalDateChange = (event) => {
      const { startDate, endDate } = event.detail;
      setDateRange({ start: startDate, end: endDate });
      loadDashboardData(startDate, endDate);
    };

    window.addEventListener('globalDateChange', handleGlobalDateChange);

    return () => {
      window.removeEventListener('globalDateChange', handleGlobalDateChange);
    };
  }, []);

  const loadDashboardData = async (startDate, endDate) => {
    try {
      const clients = await indexedDBService.getAllClients();
      
      // Convert dates to timestamps for comparison
      const startTimestamp = new Date(startDate).getTime();
      const endTimestamp = new Date(endDate).getTime();
      
      // Calculate summary data
      let totalRevenue = 0;
      let grossProfit = 0;
      let totalBookings = 0;
      const activeLocationsSet = new Set();
      const industryData = new Map();
      const clientRevenue = new Map();
      const clientProfit = new Map();
      const locationPerformance = new Map();

      clients.forEach(client => {
        client.opportunities?.forEach(opp => {
          if (!opp.CloseDate) return;

          // Convert opportunity date to timestamp for comparison
          const oppTimestamp = new Date(opp.CloseDate).getTime();
          
          // Check if opportunity is within date range and is Closed Won
          if (opp.StageName === 'Closed Won' && 
              oppTimestamp >= startTimestamp && 
              oppTimestamp <= endTimestamp) {
            const revenue = Number(opp.Total_Hire_Fees__c) || 0;
            const profit = Number(opp.Gross_Profit__c) || 0;
            
            totalRevenue += revenue;
            grossProfit += profit;
            totalBookings++;

            // Track industry data
            const industry = client.industry || 'N/A';
            if (!industryData.has(industry)) {
              industryData.set(industry, { revenue: 0, profit: 0 });
            }
            const industryStats = industryData.get(industry);
            industryStats.revenue += revenue;
            industryStats.profit += profit;

            // Track client revenue and profit
            if (!clientRevenue.has(client.clientName)) {
              clientRevenue.set(client.clientName, 0);
              clientProfit.set(client.clientName, 0);
            }
            clientRevenue.set(client.clientName, clientRevenue.get(client.clientName) + revenue);
            clientProfit.set(client.clientName, clientProfit.get(client.clientName) + profit);

            // Track location performance
            if (opp.Location_Name__r) {
              const locationName = opp.Location_Name__r.Name;
              activeLocationsSet.add(locationName);
              
              if (!locationPerformance.has(locationName)) {
                locationPerformance.set(locationName, { 
                  revenue: 0, 
                  bookings: 0,
                  profit: 0 
                });
              }
              const locationStats = locationPerformance.get(locationName);
              locationStats.revenue += revenue;
              locationStats.bookings += 1;
              locationStats.profit += profit;
            }
          }
        });
      });

      // Update all states with filtered data
      setSummaryData({
        totalRevenue,
        grossProfit,
        activeLocations: activeLocationsSet.size,
        totalBookings,
      });

      // Update industry data
      setRevenueByIndustry(
        Array.from(industryData.entries())
          .map(([label, data]) => ({
            label: truncateLabel(label),
            fullName: label,
            value: data.revenue,
            profitValue: data.profit,
          }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5)
      );

      // Update client data
      setTopClientsByRevenue(
        Array.from(clientRevenue.entries())
          .map(([name, revenue]) => ({ 
            name: truncateLabel(name),
            fullName: name,
            value: revenue,
            profitValue: clientProfit.get(name) || 0,
          }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 10)
      );

      // Update location performance
      const locationsByRevenue = Array.from(locationPerformance.entries())
        .map(([name, data]) => ({
          name: truncateLabel(name),
          fullName: name,
          revenue: data.revenue,
          profit: data.profit,
          bookings: data.bookings,
        }))
        .sort((a, b) => b.revenue - a.revenue)
        .slice(0, 10);

      setTopLocationPerformance(locationsByRevenue);

      // Calculate predictions
      setPredictedBookings(calculatePredictedBookings(clients));
      
      // Calculate and set popular designs
      const designData = calculatePopularDesigns(clients);
      setPopularDesigns(designData);
      
    } catch (error) {
      console.error('Error loading dashboard data:', error);
    }
  };

  // Common chart configuration for all bar charts
  const barChartSettings = {
    
    margin: { top: 10, bottom: 50, left: 70, right: 10 },
    slotProps: {
      legend: { hidden: true },
      tooltip: {
        followCursor: true,
        placement: 'top-start',
        componentsProps: {
          popper: {
            sx: {
              '& .MuiTooltip-tooltip': {
                bgcolor: 'transparent',
                p: 0,
                maxWidth: 'none'
              }
            }
          }
        }
      }
    },
    sx: {
      '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
        transform: 'rotate(0deg)',
        transformOrigin: 'top left',
        textAnchor: 'middle',
        dx: '-0.5em',
        dy: '0.5em',
      },
    },
  };

  // Function to truncate text
  const truncateLabel = (label, maxLength = 17) => {
    if (!label) return 'N/A';
    return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
  };

  const tooltipContent = ({ payload }) => {
    if (!payload?.length) return null;
    
    const value = payload[0]?.value;
    const formattedValue = value != null ? Number(value).toLocaleString() : 'N/A';
    
    return (
      <Box sx={{ 
        bgcolor: 'background.paper', 
        p: 1.5,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 1,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        minWidth: 150,
        maxWidth: 320
      }}>
        <Typography 
          variant="body2" 
          sx={{ 
            fontWeight: 500,
            mb: 0.5,
            wordBreak: 'break-word'
          }}
        >
          {payload[0]?.payload?.fullName || 'Unknown'}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          ${formattedValue}
        </Typography>
      </Box>
    );
  };

  const calculatePredictedBookings = (clients) => {
    const predictions = [];
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    clients.forEach(client => {
      // Group bookings by month and year
      const bookingsByMonthYear = new Map();
      const revenueByMonthYear = new Map();
      
      client.opportunities?.forEach(opp => {
        if (opp.CloseDate && opp.StageName === 'Closed Won') {
          const date = new Date(opp.CloseDate);
          const month = date.getMonth();
          const year = date.getFullYear();
          const key = `${month}-${year}`;
          
          // Track bookings count and revenue
          bookingsByMonthYear.set(key, (bookingsByMonthYear.get(key) || 0) + 1);
          revenueByMonthYear.set(key, (revenueByMonthYear.get(key) || 0) + (Number(opp.Total_Hire_Fees__c) || 0));
        }
      });

      // Check each month for consecutive year patterns
      for (let month = 0; month < 12; month++) {
        const currentYear = new Date().getFullYear();
        
        // Check if they booked in this month in the current year and previous year
        const currentYearKey = `${month}-${currentYear}`;
        const previousYearKey = `${month}-${currentYear - 1}`;
        
        const hasCurrentYearBooking = bookingsByMonthYear.has(currentYearKey);
        const hasPreviousYearBooking = bookingsByMonthYear.has(previousYearKey);

        if (hasCurrentYearBooking && hasPreviousYearBooking) {
          // Calculate total bookings and revenue for this month across all years
          let totalBookings = 0;
          let totalRevenue = 0;
          let yearsWithBookings = 0;

          // Look at last 5 years for this month to calculate probability
          for (let year = currentYear; year > currentYear - 5; year--) {
            const key = `${month}-${year}`;
            if (bookingsByMonthYear.has(key)) {
              totalBookings += bookingsByMonthYear.get(key);
              totalRevenue += revenueByMonthYear.get(key);
              yearsWithBookings++;
            }
          }

          // Calculate probability based on consistency
          const probability = (yearsWithBookings / 5) * 100;

          predictions.push({
            clientName: client.clientName,
            clientId: client.accountId,
            clientData: client,
            month: month,
            monthName: months[month],
            probability: probability,
            historicalBookings: totalBookings,
            averageRevenue: totalRevenue / totalBookings,
            consecutiveYears: 2 // We know they booked in at least 2 consecutive years
          });
        }
      }
    });

    // Sort by month first, then by probability
    return predictions.sort((a, b) => {
      if (a.month === b.month) {
        return b.probability - a.probability;
      }
      return a.month - b.month;
    });
  };

  const calculatePopularDesigns = (clients) => {
    // Track designs by month and yearly totals
    const designsByMonth = new Array(12).fill().map(() => new Map());
    const yearlyDesigns = new Map();
    
    // Collect historical data
    clients.forEach(client => {
      client.opportunities?.forEach(opp => {
        if (opp.CloseDate && opp.StageName === 'Closed Won' && opp.Location_Name__r?.Design__c) {
          const bookingMonth = new Date(opp.CloseDate).getMonth();
          const designs = opp.Location_Name__r.Design__c.split(';').map(d => d.trim());
          
          designs.forEach(design => {
            // Monthly tracking
            const currentCount = designsByMonth[bookingMonth].get(design) || 0;
            designsByMonth[bookingMonth].set(design, currentCount + 1);
            
            // Yearly tracking
            const yearlyCount = yearlyDesigns.get(design) || 0;
            yearlyDesigns.set(design, yearlyCount + 1);
          });
        }
      });
    });

    // Get top 3 designs for the year
    const topYearlyDesigns = Array.from(yearlyDesigns.entries())
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([design, bookings]) => ({
        design,
        bookings
      }));

    // Process monthly data - now showing top 5 for each month
    const monthlyData = Array.from({ length: 12 }, (_, month) => {
      const designCounts = designsByMonth[month];
      const topDesigns = Array.from(designCounts.entries())
        .sort((a, b) => b[1] - a[1])
        .slice(0, 5) // Changed from 3 to 5
        .map(([design, count]) => ({
          design,
          bookings: count
        }));

      return {
        month,
        designs: topDesigns
      };
    });

    return {
      monthly: monthlyData,
      yearly: topYearlyDesigns
    };
  };

  return (
    <Box>      
      <Grid container spacing={3}>
        {/* Summary Cards */}
        <Grid item xs={12} sm={6} md={3}>
        <Card sx={{ background: 'radial-gradient(at top center,#aa57bf,#7d20a3)', color: 'white' }}>
            <CardContent>
              <Typography color="white" gutterBottom>
                Total Revenue
              </Typography>
              <Typography variant="h4">
                ${summaryData.totalRevenue.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
        
        <Card sx={{ background: 'radial-gradient(at top center,#fb991f, #f06f04)', color: 'white' }}>
           
            <CardContent>
              <Typography color="white" gutterBottom>
                Gross Profit
              </Typography>
              <Typography variant="h4">
                ${summaryData.grossProfit.toLocaleString()}
              </Typography>
            </CardContent> 
          
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
        <Card sx={{ background: 'radial-gradient(at top center, #5cb461, #45a248)', color: 'white' }}>
            <CardContent>
              <Typography color="white" gutterBottom>
                Active Locations
              </Typography>
              <Typography variant="h4">
                {summaryData.activeLocations.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ background: 'radial-gradient(at top center, #1fb7d7, #048ad2)', color: 'white' }}>
            <CardContent>
              <Typography color="white" gutterBottom>
                Total Bookings
              </Typography>
              <Typography variant="h4">
                {summaryData.totalBookings.toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        

        {/* Revenue Analysis Section */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Revenue Analysis</Typography>
          <Grid container spacing={3}>
            {/* Top Clients by Revenue */}
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>Top 10 Clients by Revenue & Profit</Typography>
                  <Box sx={{ height: 300 }}>
                    <BarChart
                      grid={{ vertical: true, horizontal: true }}
                      dataset={topClientsByRevenue}
                      yAxis={[{ 
                        scaleType: 'linear',
                        valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                      }]}
                      xAxis={[{ 
                        scaleType: 'band', 
                        dataKey: 'name',
                        tickLabelStyle: {
                          angle: 0,
                          textAnchor: 'middle',
                        },
                      }]}
                      series={[
                        { 
                          dataKey: 'value',
                          label: 'Revenue',
                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                          color: '#aa57bf', // Match the revenue card color
                        },
                        { 
                          dataKey: 'profitValue',
                          label: 'Profit',
                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                          color: '#fb991f', // Match the profit card color
                        }
                      ]}
                      height={300}
                      layout="vertical"
                      {...barChartSettings}
                      slotProps={{
                        ...barChartSettings.slotProps,
                        legend: {
                          hidden: false,
                          direction: 'row',
                          position: { vertical: 'bottom', horizontal: 'middle' },
                          padding: { top: 20, bottom: 0 },
                        },
                        tooltip: {
                          content: tooltipContent,
                        },
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            {/* Revenue by Industry */}
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>Top 5 Industries by Revenue & Profit</Typography>
                  <Box sx={{ height: 400 }}>
                    <BarChart
                      dataset={revenueByIndustry}
                      grid={{ vertical: true, horizontal: true }}
                      yAxis={[{ 
                        scaleType: 'linear',
                        valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                      }]}
                      xAxis={[{ 
                        scaleType: 'band', 
                        dataKey: 'label',
                        tickLabelStyle: {
                          angle: 0,
                          textAnchor: 'middle',
                        },
                      }]}
                      series={[
                        { 
                          dataKey: 'value',
                          label: 'Revenue',
                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                          color: '#aa57bf', // Match the revenue card color
                        },
                        { 
                          dataKey: 'profitValue',
                          label: 'Profit',
                          valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                          color: '#fb991f', // Match the profit card color
                        }
                      ]}
                      height={400}
                      
                      layout="vertical"
                      {...barChartSettings}
                      slotProps={{
                        ...barChartSettings.slotProps,
                        legend: {
                          hidden: false,
                          direction: 'row',
                          position: { vertical: 'bottom', horizontal: 'middle' },
                          padding: { top: 20, bottom: 0 },
                        },
                        tooltip: {
                          content: tooltipContent,
                        },
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    Predicted Client Bookings by Month
                  </Typography>
                  <Box sx={{ 
                    height: 400, 
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: '0.4em'
                    },
                    '&::-webkit-scrollbar-track': {
                      background: '#f1f1f1'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#888',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                      background: '#555'
                    }
                  }}>
                    {Array.from({ length: 12 }, (_, month) => {
                      const monthPredictions = predictedBookings
                        .filter(pred => pred.month === month)
                        .slice(0, 10);
                        
                      if (monthPredictions.length === 0) return null;
                      
                      return (
                        <Box key={month} sx={{ mb: 3 }}>
                          <Typography 
                            variant="subtitle2" 
                            sx={{ 
                              mb: 1,
                              color: 'black',
                              px: 2,
                              fontWeight: 500,
                              letterSpacing: 1,
                            }}
                          >
                            {new Date(2024, month).toLocaleString('default', { month: 'long' })}
                          </Typography>
                          {monthPredictions.map((prediction, index) => (
                            <Box key={index} sx={{ 
                              mb: 1.5,
                              border: '1px solid #e0e0e0',
                              background: 'linear-gradient(to bottom right, #fafafa, #ffffff)', // Changed to background
                              borderRadius: 1,
                              display: 'flex', 
                              alignItems: 'center', 
                              gap: 2,
                              px: 2,
                              py: 1,
                              '&:hover': {
                                bgcolor: 'action.hover',
                                borderRadius: 1,
                              }
                            }}>
                              <Box sx={{ flex: 1}}>
                                <Typography 
                                  variant="body2" 
                                  sx={{ 
                                    cursor: 'pointer',
                                    color: 'primary.main',
                                    fontWeight: 500,
                                    '&:hover': {
                                      textDecoration: 'underline'
                                    }
                                  }}
                                  onClick={() => navigate(`/clients/${prediction.clientId}`, {
                                    state: { client: prediction.clientData }
                                  })}
                                >
                                  {prediction.clientName}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                  {prediction.historicalBookings} historical bookings
                                </Typography>
                              </Box>
                              <Box sx={{ 
                                bgcolor: '#4fa954',
                                color: 'white',
                                px: 1.5,
                                py: 0.5,
                                borderRadius: 10,
                                minWidth: 90,
                                textAlign: 'center'
                              }}>
                                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                                  {prediction.probability.toFixed(0)}% likely
                                </Typography>
                              </Box>
                              <Typography variant="caption" sx={{ 
                                color: 'text.secondary',
                                minWidth: 80,
                                textAlign: 'right'
                              }}>
                                ~${prediction.averageRevenue.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      );
                    })}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        

        {/* Top Location Performance */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Top 10 Locations Performance</Typography>
              <Box sx={{ height: 500 }}>
                <BarChart
                  grid={{ vertical: true, horizontal: true }}
                  dataset={topLocationPerformance}
                  yAxis={[
                    { 
                      id: 'money',
                      scaleType: 'linear',
                      valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                    },
                    { 
                      id: 'bookings',
                      scaleType: 'linear',
                      position: 'right',
                      valueFormatter: (value) => value != null ? value.toString() : 'N/A',
                    }
                  ]}
                  xAxis={[{ 
                    scaleType: 'band', 
                    dataKey: 'name',
                    tickLabelStyle: {
                      angle: 0,
                      textAnchor: 'middle',
                    },
                  }]}
                  series={[
                    { 
                      dataKey: 'revenue',
                      label: 'Revenue',
                      valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                      color: '#aa57bf', // Match revenue card color
                      yAxisKey: 'money',
                    },
                    { 
                      dataKey: 'profit',
                      label: 'Profit',
                      valueFormatter: (value) => value != null ? `$${value.toLocaleString()}` : 'N/A',
                      color: '#fb991f', // Match profit card color
                      yAxisKey: 'money',
                    },
                    {
                      dataKey: 'bookings',
                      label: 'Number of Bookings',
                      valueFormatter: (value) => value != null ? value.toString() : 'N/A',
                      color: '#5cb461', // Match active locations card color
                      yAxisKey: 'bookings',
                    }
                  ]}
                  height={500}
                  layout="vertical"
                  {...barChartSettings}
                  slotProps={{
                    ...barChartSettings.slotProps,
                    legend: {
                      hidden: false,
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: { top: 20, bottom: 0 },
                    },
                    tooltip: {
                      content: ({ payload }) => {
                        if (!payload?.length) return null;
                        
                        return (
                          <Box sx={{ 
                            bgcolor: 'background.paper', 
                            p: 1.5,
                            border: '1px solid rgba(0, 0, 0, 0.12)',
                            borderRadius: 1,
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                          }}>
                            <Typography variant="body2" sx={{ fontWeight: 500, mb: 1 }}>
                              {payload[0]?.payload?.fullName || 'Unknown'}
                            </Typography>
                            {payload.map((entry, index) => (
                              <Typography key={index} variant="body2" color="text.secondary">
                                {entry.name}: {entry.name.includes('Bookings') 
                                  ? entry.value 
                                  : `$${entry.value?.toLocaleString() ?? 'N/A'}`}
                              </Typography>
                            ))}
                          </Box>
                        );
                      },
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* Booking Predictions Section */}
        <Grid item xs={12}>
      
          <Grid container spacing={3}>
            {/* Predicted Bookings */}
            

            {/* Popular Design Predictions */}
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Palette color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6">All Time Most Popular Designs</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    {/* Podium Visualization */}
                    <Grid item xs={12} md={3.5}>
                      <Box sx={{ 
                        height: 400, 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        position: 'relative',
                        p: 2,
                        background: 'white',
                        borderRadius: 2,
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                        width: '100%',
                      }}>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            position: 'absolute',
                            top: 16,
                            left: 16,
                            color: 'text.secondary',
                            fontSize: '1rem',
                            fontWeight: 500
                          }}
                        >
                          Top 3 Overall
                        </Typography>
                        {popularDesigns?.yearly?.map((design, index) => {
                          const podiumHeights = [180, 140, 100];
                          const podiumColors = [
                            'linear-gradient(135deg, #FFD700 0%, #FFC300 100%)',
                            'linear-gradient(135deg, #C0C0C0 0%, #A6A6A6 100%)',
                            'linear-gradient(135deg, #CD7F32 0%, #B88B5B 100%)'
                          ];
                          const positions = [1, 0, 2];
                          const trophyColors = ['#FFD700', '#C0C0C0', '#CD7F32'];

                          return (
                            <Box
                              key={index}
                              sx={{
                                position: 'absolute',
                                bottom: 40,
                                left: `${20 + (positions[index] * 30)}%`,
                                width: '28%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                transform: 'translateX(-50%)',
                              }}
                            >
                              {/* Design Name Box */}
                              <Box
                                sx={{
                                  bgcolor: 'background.paper',
                                  p: 1,
                                  borderRadius: 1,
                                  border: '1px solid',
                                  borderColor: 'divider',
                                  width: '100%',
                                  mb: 1,
                                  textAlign: 'center',
                                  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                  transition: 'transform 0.2s',
                                  '&:hover': {
                                    transform: 'scale(1.05)',
                                  }
                                }}
                              >
                                <Typography variant="caption" sx={{ fontWeight: 500 }}>
                                  {design.design}
                                </Typography>
                                <Typography variant="caption" display="block" color="text.secondary">
                                  {design.bookings} bookings
                                </Typography>
                              </Box>

                              {/* Trophy Icon */}
                              <EmojiEvents 
                                sx={{ 
                                  color: trophyColors[index],
                                  fontSize: 32,
                                  mb: 1,
                                  filter: 'drop-shadow(0 2px 4px rgba(0,0,0,0.2))'
                                }} 
                              />

                              {/* Podium Block */}
                              <Box
                                sx={{
                                  width: '100%',
                                  height: podiumHeights[index],
                                  backgroundImage: podiumColors[index],
                                  borderTopLeftRadius: 4,
                                  borderTopRightRadius: 4,
                                  display: 'flex',
                                  alignItems: 'flex-start',
                                  justifyContent: 'center',
                                  pt: 1,
                                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                                  transition: 'transform 0.2s',
                                  '&:hover': {
                                    transform: 'translateY(-5px)',
                                  }
                                }}
                              >
                                <Typography 
                                  variant="h4" 
                                  sx={{ 
                                    color: 'white',
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={0.5}></Grid>
                    <Grid item xs={12} md={8}>
                      <Box sx={{ 
                        height: 360, 
                        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                        borderRadius: 2,
                        background: 'linear-gradient(to bottom right, #fafafa, #ffffff)',
                        overflow: 'auto',
                        px: 3,
                        py: 2,
                        '&::-webkit-scrollbar': { width: '6px' },
                        '&::-webkit-scrollbar-track': { background: '#f1f1f1', borderRadius: '3px' },
                        '&::-webkit-scrollbar-thumb': { 
                          backgroundColor: '#888',
                          borderRadius: '3px',
                          '&:hover': { backgroundColor: '#666' }
                        }
                      }}>
                        {popularDesigns?.monthly?.map(({ month, designs }) => {
                          if (!designs?.length) return null;
                          
                          return (
                            <Box 
                              key={month} 
                              sx={{ 
                                mb: 2,
                                p: 2,
                                borderRadius: 2,
                                transition: 'all 0.2s',
                                background: 'white',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                                '&:hover': {
                                  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                  transform: 'translateX(5px)'
                                }
                              }}
                            >
                              <Box sx={{ 
                                display: 'flex', 
                                alignItems: 'center', 
                                mb: 1,
                                pb: 1,
                                borderBottom: '1px solid rgba(0,0,0,0.05)'
                              }}>
                                <CalendarMonth 
                                  color="primary" 
                                  sx={{ 
                                    mr: 1,
                                    fontSize: 20,
                                    opacity: 0.8
                                  }} 
                                />
                                <Typography 
                                  variant="subtitle2" 
                                  sx={{ 
                                    color: 'primary.main',
                                    fontWeight: 600,
                                    fontSize: 16,
                                    letterSpacing: '0.5px'
                                  }}
                                >
                                  {new Date(2024, month).toLocaleString('default', { month: 'long' })}
                                </Typography>
                              </Box>
                              <Box sx={{ 
                                display: 'flex', 
                                flexWrap: 'wrap', 
                                gap: 1,
                                ml: 4
                              }}>
                                {designs.map((design, index) => (
                                  <Chip
                                    key={design.design}
                                    label={
                                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                        <Typography component="span" sx={{ fontWeight: index === 0 ? 600 : 400 }}>
                                          {design.design}
                                        </Typography>
                                        <Typography 
                                          component="span" 
                                          sx={{ 
                                            opacity: 0.8,
                                            fontSize: '0.85em',
                                            fontWeight: 400
                                          }}
                                        >
                                          ({design.bookings})
                                        </Typography>
                                      </Box>
                                    }
                                    size="small"
                                    sx={{ 
                                      borderRadius: '6px',
                                      bgcolor: designRankColors[index]?.bg || 'grey.300',
                                      color: designRankColors[index]?.text || '#000000',
                                      border: 'none',
                                      py: 0.5,
                                      '&:hover': {
                                        bgcolor: designRankColors[index]?.hover || 'grey.400',
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                      },
                                      fontWeight: index === 0 ? 600 : 400,
                                      transition: 'all 0.2s ease',
                                      '& .MuiChip-label': {
                                        px: 1.5
                                      }
                                    }}
                                  />
                                ))}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard; 