import salesforceService from './salesforceService';
import indexedDBService from './indexedDBService';

class DataSyncService {
  constructor() {
    this.syncInterval = 5 * 60 * 1000; // 5 minutes
    this.syncTimer = null;
    this.isSyncing = false;
  }

  async startSync() {
    if (this.syncTimer) {
      return;
    }

    // Initial sync
    await this.syncData();

    // Set up periodic sync
    this.syncTimer = setInterval(() => {
      this.syncData();
    }, this.syncInterval);
  }

  stopSync() {
    if (this.syncTimer) {
      clearInterval(this.syncTimer);
      this.syncTimer = null;
    }
  }

  async syncData() {
    if (this.isSyncing) {
      return;
    }

    this.isSyncing = true;
    try {
      console.log('Starting data sync...');
      const { clients, locations } = await salesforceService.batchQuery();
      console.log('Received data from Salesforce:', { 
        clientsCount: clients?.length || 0, 
        locationsCount: locations?.length || 0,
        sampleClient: clients?.[0],
        sampleLocation: locations?.[0]
      });

      // Clear existing data and save new data
      console.log('Clearing existing data...');
      await Promise.all([
        indexedDBService.clearClients(),
        indexedDBService.clearLocations()
      ]);

      console.log('Saving new data...');
      await Promise.all([
        indexedDBService.saveClients(clients || []),
        indexedDBService.saveLocations(locations || [])
      ]);

      console.log(`Synced ${clients?.length || 0} clients and ${locations?.length || 0} locations`);

      // Dispatch event to notify components
      window.dispatchEvent(new CustomEvent('datasynccomplete'));
    } catch (error) {
      console.error('Sync failed:', error);
      throw error;
    } finally {
      this.isSyncing = false;
    }
  }

  async getLastSyncTime() {
    const [clients, locations] = await Promise.all([
      indexedDBService.getAllClients(),
      indexedDBService.getAllLocations()
    ]);

    if (clients.length === 0 && locations.length === 0) {
      return null;
    }

    const clientLastSync = clients.length > 0 
      ? Math.max(...clients.map(c => new Date(c.lastSyncedAt).getTime()))
      : 0;
    
    const locationLastSync = locations.length > 0
      ? Math.max(...locations.map(l => new Date(l.lastSyncedAt).getTime()))
      : 0;

    return Math.max(clientLastSync, locationLastSync);
  }
}

export default new DataSyncService(); 