import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,

  CircularProgress,
  Tooltip,
  Divider,
  IconButton
} from '@mui/material';
import { 
  Warning as WarningIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  OpenInNew as OpenInNewIcon,

} from '@mui/icons-material';
import { 
  DataGridPro,
  GridToolbarContainer, 
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
  GridCsvExportMenuItem
} from '@mui/x-data-grid-pro';
import indexedDBService from '../services/indexedDBService';
import { useNavigate } from 'react-router-dom';

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExportContainer>
        <GridCsvExportMenuItem options={{
          fileName: 'locations_export',
          utf8WithBom: true,
          fields: ['name', 'design', 'bookingCount', 'totalRevenue', 'grossProfit', 'conversionRate']
        }} />
      </GridToolbarExportContainer>
      <GridToolbarQuickFilter />
    </GridToolbarContainer>
  );
};

const Locations = () => {
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState(() => ({ 
    start: new Date(localStorage.getItem('globalStartDate') || new Date().setFullYear(new Date().getFullYear() - 1)), 
    end: new Date(localStorage.getItem('globalEndDate') || new Date()) 
  }));
  const [atRiskLocations, setAtRiskLocations] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleGlobalDateChange = (event) => {
      const { startDate, endDate } = event.detail;
      setDateRange({ 
        start: new Date(startDate), 
        end: new Date(endDate) 
      });
    };

    window.addEventListener('globalDateChange', handleGlobalDateChange);
    return () => {
      window.removeEventListener('globalDateChange', handleGlobalDateChange);
    };
  }, []);

  useEffect(() => {
    const loadLocations = async () => {
      setIsLoading(true);
      try {
        const [locations, clients] = await Promise.all([
          indexedDBService.getAllLocations(),
          indexedDBService.getAllClients()
        ]);

        const startDate = new Date(dateRange.start);
        const endDate = new Date(dateRange.end);

        // Calculate the previous period
        const periodLength = endDate.getTime() - startDate.getTime();
        const previousPeriodStart = new Date(startDate.getTime() - periodLength);
        const previousPeriodEnd = startDate;

        const locationStats = new Map();

        // Initialize stats for each location
        locations.forEach(location => {
          locationStats.set(location.name, {
            ...location,
            bookingCount: 0,
            totalRevenue: 0,
            previousPeriodRevenue: 0,
            previousPeriodBookings: 0,
            potentialBookings: 0,
            previousPeriodPotentialBookings: 0,
            grossProfit: 0,
            previousPeriodGrossProfit: 0
          });

          const stats = locationStats.get(location.name);

          // Calculate potential bookings for current period
          location.potentialLocations?.forEach(pl => {
            const createdDate = new Date(pl.CreatedDate);
            if (createdDate >= startDate && createdDate <= endDate) {
              if (pl.Opportunity__r?.Name) {
                stats.potentialBookings++;
              }
            }
            // Calculate potential bookings for previous period
            else if (createdDate >= previousPeriodStart && createdDate < previousPeriodEnd) {
              if (pl.Opportunity__r?.Name) {
                stats.previousPeriodPotentialBookings++;
              }
            }
          });
        });

        // Process opportunities for both periods
        clients.forEach(client => {
          const processedOpps = new Map();

          client.opportunities?.forEach(opp => {
            const closeDate = new Date(opp.CloseDate);
            const locationName = opp.Location_Name__r?.Name;
            
            if (locationName && locationStats.has(locationName)) {
              if (!processedOpps.has(locationName)) {
                processedOpps.set(locationName, new Set());
              }

              if (!processedOpps.get(locationName).has(opp.Name)) {
                const stats = locationStats.get(locationName);
                
                // Current period
                if (closeDate >= startDate && closeDate <= endDate) {
                  stats.bookingCount++;
                  stats.totalRevenue += Number(opp.Total_Hire_Fees__c) || 0;
                  stats.grossProfit += Number(opp.Gross_Profit__c) || 0;
                }
                // Previous period
                else if (closeDate >= previousPeriodStart && closeDate < previousPeriodEnd) {
                  stats.previousPeriodBookings++;
                  stats.previousPeriodRevenue += Number(opp.Total_Hire_Fees__c) || 0;
                  stats.previousPeriodGrossProfit += Number(opp.Gross_Profit__c) || 0;
                }
                
                processedOpps.get(locationName).add(opp.Name);
              }
            }
          });
        });

        // Calculate conversion rates and trends
        locationStats.forEach(stats => {
          // Current period conversion rate
          stats.conversionRate = stats.potentialBookings > 0 
            ? (stats.bookingCount / stats.potentialBookings) * 100 
            : 0;

          // Revenue trend: percentage change from previous period
          stats.trend = stats.previousPeriodRevenue > 0
            ? ((stats.totalRevenue - stats.previousPeriodRevenue) / stats.previousPeriodRevenue) * 100
            : 0;
        });

        if (startDate && endDate) {
          // Calculate the previous period
          const periodLength = endDate.getTime() - startDate.getTime();
          const previousPeriodStart = new Date(startDate.getTime() - periodLength);
          const previousPeriodEnd = startDate;

          const riskLocations = Array.from(locationStats.values())
            .filter(location => {
              // Only include locations that had any revenue in either period
              return location.totalRevenue > 0 || location.previousPeriodRevenue > 0;
            })
            .map(location => {
              // Calculate revenue drop using the correct period values
              const currentPeriodRevenue = location.totalRevenue || 0;
              const previousPeriodRevenue = location.previousPeriodRevenue || 0;

              const revenueDrop = previousPeriodRevenue > 0 ? 
                ((previousPeriodRevenue - currentPeriodRevenue) / previousPeriodRevenue) * 100 : 0;

              return {
                ...location,
                revenueDrop: revenueDrop || 0,
                revenueDropValue: (previousPeriodRevenue - currentPeriodRevenue) || 0,
                // Add period information for debugging if needed
                currentPeriodStart: startDate,
                currentPeriodEnd: endDate,
                previousPeriodStart: previousPeriodStart,
                previousPeriodEnd: previousPeriodEnd
              };
            })
            .filter(location => (
              // Only include locations with positive revenue drop and previous period revenue
              location.revenueDrop > 0 && 
              location.previousPeriodRevenue > 0
            ))
            .sort((a, b) => (b.revenueDropValue || 0) - (a.revenueDropValue || 0))
            .slice(0, 20);

          setAtRiskLocations(riskLocations);
        }

        setLocations(Array.from(locationStats.values()));
      } catch (error) {
        console.error('Error loading locations:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadLocations();
  }, [dateRange]);

  const columns = [
    {
      field: 'name',
      headerName: 'Location Name',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box
          onClick={() => navigate(`/locations/${params.row.accountId}`, {
            state: { location: params.row }
          })}
          sx={{
            cursor: 'pointer',
            color: 'primary.main',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'design',
      headerName: 'Design Style',
      flex: 0.5,
      minWidth: 150,
    },
    {
      field: 'bookingCount',
      headerName: 'Total Bookings',
      type: 'number',
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => {
        const currentValue = params.value || 0;
        const previousValue = params.row.previousPeriodBookings || 0;
        const color = currentValue > previousValue ? '#4caf50' : 
                      currentValue < previousValue ? '#f44336' : '#757575';
        
        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: {currentValue}
                </Typography>
                <Typography variant="body2">
                  Previous Period: {previousValue}
                </Typography>
              </Box>
            }
            arrow
          >
            <Box sx={{ color }}>
              {currentValue.toLocaleString()}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'potentialBookings',
      headerName: 'Potential Bookings',
      type: 'number',
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => {
        const currentValue = params.value || 0;
        const previousValue = params.row.previousPeriodPotentialBookings || 0;
        const color = currentValue > previousValue ? '#4caf50' : 
                      currentValue < previousValue ? '#f44336' : '#757575';
        
        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: {currentValue}
                </Typography>
                <Typography variant="body2">
                  Previous Period: {previousValue}
                </Typography>
              </Box>
            }
            arrow
          >
            <Box sx={{ color }}>
              {currentValue.toLocaleString()}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'totalRevenue',
      headerName: 'Total Revenue',
      type: 'number',
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => {
        const currentValue = params.value || 0;
        const previousValue = params.row.previousPeriodRevenue || 0;
        const color = currentValue > previousValue ? '#4caf50' : 
                      currentValue < previousValue ? '#f44336' : '#757575';
        
        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: ${currentValue.toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  Previous Period: ${previousValue.toLocaleString()}
                </Typography>
              </Box>
            }
            arrow
          >
            <Box sx={{ color }}>
              ${currentValue.toLocaleString()}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'grossProfit',
      headerName: 'Gross Profit',
      type: 'number',
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => {
        const currentValue = params.value || 0;
        const previousValue = params.row.previousPeriodGrossProfit || 0;
        const color = currentValue > previousValue ? '#4caf50' : 
                      currentValue < previousValue ? '#f44336' : '#757575';
        
        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: ${currentValue.toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  Previous Period: ${previousValue.toLocaleString()}
                </Typography>
              </Box>
            }
            arrow
          >
            <Box sx={{ color }}>
              ${currentValue.toLocaleString()}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'conversionRate',
      headerName: 'Conversion Rate',
      type: 'number',
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => {
        const currentValue = params.value || 0;
        // Calculate previous period conversion rate
        const previousPotential = params.row.previousPeriodPotentialBookings || 1; // Avoid division by zero
        const previousBookings = params.row.previousPeriodBookings || 0;
        const previousRate = (previousBookings / previousPotential) * 100;
        
        const color = currentValue > previousRate ? '#4caf50' : 
                      currentValue < previousRate ? '#f44336' : '#757575';
        
        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: {currentValue.toFixed(1)}%
                </Typography>
                <Typography variant="body2">
                  Previous Period: {previousRate.toFixed(1)}%
                </Typography>
              </Box>
            }
            arrow
          >
            <Box sx={{ color }}>
              {currentValue.toFixed(1)}%
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: 'trend',
      headerName: 'Revenue Trend',
      type: 'number',
      flex: 0.5,
      minWidth: 120,
      renderCell: (params) => {
        const value = params.value || 0;
        const color = value > 0 ? '#4caf50' : value < 0 ? '#f44336' : '#757575';
        const arrow = value > 0 ? <TrendingUpIcon fontSize="small" /> : value < 0 ? <TrendingDownIcon fontSize="small" /> : '→';
        
        return (
          <Tooltip
            title={
              <Box sx={{ p: 1 }}>
                <Typography variant="body2">
                  Current Period: ${params.row.totalRevenue.toLocaleString()}
                </Typography>
                <Typography variant="body2">
                  Previous Period: ${params.row.previousPeriodRevenue.toLocaleString()}
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Typography variant="body2" sx={{ color }}>
                  {value > 0 ? 'Increase' : value < 0 ? 'Decrease' : 'No Change'}: {Math.abs(value).toFixed(1)}%
                </Typography>
              </Box>
            }
            arrow
          >
            <Box
              sx={{
                color,
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
              }}
            >
              {arrow} {Math.abs(value).toFixed(1)}%
            </Box>
          </Tooltip>
        );
      },
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 0.5,
        minWidth: 100,
        renderCell: (params) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              sx={{
                backgroundColor: params.value === 'Active Location' ? '#4caf50' : '#ff9800',
                color: 'white',
                padding: '4px 12px',
                borderRadius: '50px',
                fontSize: '0.75rem',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '24px',
              }}
            >
              {params.value === 'Active Location' ? 'Active' : params.value || 'N/A'}
            </Box>
          </Box>
        ),
      },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      minWidth: 70,
      sortable: false,
      renderCell: (params) => {
        const handleClick = () => {
          // Get the Salesforce base URL from environment variable or use a default
          const sfBaseUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://purelocations.lightning.force.com';
          const accountId = params.row.accountId;
          
          // Construct and open the Salesforce URL
          const sfUrl = `${sfBaseUrl}/lightning/r/Account/${accountId}/view`;
          window.open(sfUrl, '_blank');
        };

        return (
          <IconButton 
            size="small" 
            onClick={handleClick}
            title="Open in Salesforce"
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        );
      },
    }
  ];

  const filteredLocations = locations.filter(location => {
    const searchRegex = new RegExp(searchTerm, 'i');
    return (
      searchRegex.test(location.name) ||
      searchRegex.test(location.address) ||
      searchRegex.test(location.design)
    );
  });

  return (
    <Box>
      <Grid container spacing={3} sx={{ height: 'calc(100vh - 88px)', overflow: 'hidden' }}>
        {/* Main locations grid */}
        <Grid item xs={12} md={10} sx={{ height: '100%' }}>
          <Box sx={{ 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column',
            overflow: 'hidden',
            backgroundColor: '#FFFFFF',
            padding: 2,
            borderRadius: 1,
            boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.1)'
          }}>
            <Box sx={{ 
              flex: 1,
              overflow: 'hidden'
            }}>
              {isLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <CircularProgress />
                </Box>
              ) : (
                <DataGridPro
                  rows={filteredLocations}
                  columns={columns}
                  density="comfortable"
                  pagination
                  getRowId={(row) => row.name}
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    toolbar: {
                      printOptions: { 
                        hideFooter: true,
                        hideToolbar: true,
                      },
                      csvOptions: { 
                        fileName: 'locations_export',
                        utf8WithBom: true,
                      },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 }
                    }
                  }}
                  sx={{
                    height: '100%',
                    '& .MuiDataGrid-main': { overflow: 'hidden' },
                    // Add zebra striping
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                      backgroundColor: 'rgba(255, 255, 255, 0)',
                    },
                    // Hover effect
                    '& .MuiDataGrid-row:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.04)',
                    },
                    // Header styling
                    '& .MuiDataGrid-columnHeaders': {
                      backgroundColor: '#ffffff',
                      color: 'rgba(0, 0, 0, 0.87)',
                      fontSize: '0.875rem',
                      fontWeight: 600,
                    },
                    // Add this for the top container row
                    '& .MuiDataGrid-container--top [role=row]': {
                      backgroundColor: '#ffffff',
                    },
                    '& .MuiDataGrid-row': {
                      backgroundColor: '#FFFFFFBC',
                    },
                    // Selected row
                    '& .MuiDataGrid-row.Mui-selected': {
                      backgroundColor: 'rgba(25, 118, 210, 0.08)',
                      '&:hover': {
                        backgroundColor: 'rgba(25, 118, 210, 0.12)',
                      },
                    },
                    // Footer styling
                    '& .MuiDataGrid-footerContainer': {
                      borderTop: '1px solid rgba(224, 224, 224, 1)',
                      backgroundColor: 'rgba(255, 255, 255, 1)',
                      color: 'rgba(0, 0, 0, 0.87)',
                    },
                    // Density styles (already present)
                    '& .MuiDataGrid-row': {
                      '&.MuiDataGrid-row--densityCompact': {
                        maxHeight: '32px',
                      },
                      '&.MuiDataGrid-row--densityStandard': {
                        maxHeight: '52px',
                      },
                      '&.MuiDataGrid-row--densityComfortable': {
                        maxHeight: '64px',
                      },
                    }
                  }}
                  initialState={{
                    pagination: { pageSize: 25 },
                    sorting: {
                      sortModel: [{ field: 'bookingCount', sort: 'desc' }],
                    }
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>

        {/* New Risk Panel */}
        <Grid item xs={12} md={2} sx={{ height: '100%' }}>
          <Card sx={{ height: '100%' }}>
            <CardContent sx={{ 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              p: 0,
              '&:last-child': { pb: 0 }
            }}>
              <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
                <Typography variant="p" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <WarningIcon color="error" />
                  Top 20 Locations at Risk
                </Typography>
              </Box>
              
              <Box sx={{ 
                flex: 1,
                overflow: 'auto',
                p: 2
              }}>
                {atRiskLocations.map((location) => (
                  <Box key={location.accountId} sx={{ mb: 2 }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        cursor: 'pointer',
                        color: 'primary.main',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={() => navigate(`/locations/${location.accountId}`, {
                        state: { location: location }
                      })}
                    >
                      {location.name}
                    </Typography>
                    <Typography color="error" variant="caption" component="div">
                      Revenue drop: ${(location.revenueDropValue || 0).toLocaleString()}
                    </Typography>
                    <Typography color="error" variant="caption" component="div">
                      ({(location.revenueDrop || 0).toFixed(1)}% decrease)
                    </Typography>
                    <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
                      Previous: ${(location.previousPeriodRevenue || 0).toLocaleString()} → 
                      Current: ${(location.totalRevenue || 0).toLocaleString()}
                    </Typography>
                    <Divider sx={{ my: 2, borderColor: '#f4f4f4' }} />
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Locations; 