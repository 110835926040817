// Add rate limiting to respect OpenStreetMap's usage policy
const queue = [];
let processing = false;
const RATE_LIMIT_MS = 1000; // 1 second between requests

const processQueue = async () => {
  if (processing || queue.length === 0) return;
  processing = true;

  const { address, resolve, reject } = queue.shift();

  try {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(address)}&limit=1`
    );
    const data = await response.json();
    
    if (data && data[0]) {
      resolve([parseFloat(data[0].lat), parseFloat(data[0].lon)]);
    } else {
      resolve(null);
    }
  } catch (error) {
    reject(error);
  }

  setTimeout(() => {
    processing = false;
    processQueue();
  }, RATE_LIMIT_MS);
};

const geocodeAddress = (address) => {
  return new Promise((resolve, reject) => {
    queue.push({ address, resolve, reject });
    processQueue();
  });
};

export default {
  geocodeAddress
}; 