import axios from 'axios';

const API_BASE_URL = 'https://bdm.purelocations.com.au/api/sf';


const BATCH_SIZE = 199;
const QUERY_DELAY = 200;

// Enhanced logging
const log = (type, message, data = null) => {
  const timestamp = new Date().toISOString();
  console.log(`[${timestamp}] [SF-${type}] ${message}`);
  if (data) {
    console.log(JSON.stringify(data, null, 2));
  }
};

// Error logging
const logError = (type, error, context = {}) => {
  const timestamp = new Date().toISOString();
  console.error(`[${timestamp}] [SF-ERROR] [${type}]`, {
    message: error.message,
    response: error.response?.data,
    status: error.response?.status,
    context,
    stack: error.stack
  });
};

class SalesforceService {
  constructor() {
    this.accessToken = null;
    this.instanceUrl = null;
    this.retryCount = 0;
    this.maxRetries = 3;
    this.retryDelay = 1000;
  }

  async sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async authenticate() {
    try {
      log('AUTH', 'Initiating authentication');
      const response = await axios.post(`${API_BASE_URL}/auth`);

      log('AUTH', 'Authentication successful', {
        hasAccessToken: !!response.data.access_token,
        instanceUrl: response.data.instance_url
      });

      this.accessToken = response.data.access_token;
      this.instanceUrl = response.data.instance_url;
      return response.data;
    } catch (error) {
      logError('AUTH', error);
      throw new Error('Failed to authenticate with Salesforce. Please check your credentials.');
    }
  }

  async getAllAccountIds() {
    try {
      log('QUERY', 'Starting to fetch all account IDs');
      let allIds = [];
      let done = false;
      let nextRecordsUrl = null;
      let batchNumber = 0;

      const initialQuery = `
        SELECT 
          Id,
          Name,
          RecordTypeId
        FROM Account 
        WHERE RecordTypeId IN ('0125g0000004aLaAAI', '0125g0000004aLfAAI')
        ORDER BY Name ASC NULLS LAST
      `;

      while (!done) {
        batchNumber++;
        log('QUERY', `Fetching account IDs batch #${batchNumber}`);

        try {
          const response = await axios.post(`${API_BASE_URL}/query`, {
            query: batchNumber === 1 ? initialQuery : null,
            nextRecordsUrl
          });

          const records = response.data.records;
          allIds = allIds.concat(records.map(record => record.Id));
          
          done = !response.data.nextRecordsUrl;
          nextRecordsUrl = response.data.nextRecordsUrl;

          log('QUERY', `Batch #${batchNumber} complete`, {
            batchSize: records.length,
            totalIdsSoFar: allIds.length,
            hasMore: !done,
            totalSize: response.data.totalSize,
           
          });

          if (!done) {
            await this.sleep(QUERY_DELAY);
          }
        } catch (error) {
          logError('QUERY', error, {
            context: 'getAllAccountIds',
            batchNumber,
            totalIdsSoFar: allIds.length
          });

          if (allIds.length > 0) {
            log('QUERY', `Returning partial results due to error`, {
              totalIds: allIds.length
            });
            return allIds;
          }
          throw error;
        }
      }

      log('QUERY', 'Completed fetching all account IDs', {
        totalIds: allIds.length
      });

      return allIds;
    } catch (error) {
      logError('QUERY', error, { context: 'getAllAccountIds' });
      throw error;
    }
  }

  async getAccountsByIds(ids) {
    try {
      log('QUERY', 'Fetching accounts by IDs', {
        idCount: ids.length,
        sampleIds: ids.slice(0, 5)
      });

      const query = `
        SELECT 
          Id,
          Name,
          RecordTypeId,
          Industry_Sectors__c,
          Address__c,
          Client_Status__c,
          Phone,
          Website,
          Location_Status__c,
          OwnerId,
          Owner.Name,
          Owner.MediumPhotoUrl,
          Owner.Email,
          AnnualRevenue,
          Client_Bond__c,
          Design__c,
          Client_Cleaning_Fee__c,
          Location_Tier__c,
          IsDeleted,
          SystemModstamp,
          LastModifiedDate,
          (
            SELECT 
              Id,
              Total_Hire_Fees__c,
              CloseDate,
              Name,
              Brand__c,
              Amount,
              Net_Profit__c,
              Gross_Profit__c,
              Revenue__c,
              GST__c,
              Total_Hire_Fees_GST__c,
              StageName,
              Type,
              Location_Name__r.Name,
              Location_Name__r.Address__c,
              Location_Name__r.Design__c,
              Location_Name__r.Location_Tier__c
            FROM Opportunities
            WHERE StageName = 'Closed Won'
            ORDER BY CloseDate DESC
          ),
          (
            SELECT 
              Id,
              Name,
              Opportunity__r.Name,
              Opportunity__r.Location_Name__c,
              Opportunity__r.Location_Name__r.Name,
              Opportunity__r.Total_Hire_Fees__c,
              Opportunity__r.Start_Date_Time__c,
              Opportunity__r.Date_From__c,
              Opportunity__r.CloseDate,
              CreatedDate,
              Recce_Stage__c,
              Recce_Start_Date_Time__c
            FROM Potential_Locations__r
            ORDER BY CreatedDate DESC
          )
        FROM Account 
        WHERE Id IN ('${ids.join("','")}')
        ORDER BY Name ASC
      `;

      const response = await axios.post(`${API_BASE_URL}/query`, { query });
      
      // Add debugging for important location
      const importantLocation = response.data.records.find(record => record.Id === '0015g000003WiY4AAK');
      if (ids.includes('0015g000003WiY4AAK')) {
        log('QUERY', 'Important Location Check', {
          locationId: '0015g000003WiY4AAK',
          found: !!importantLocation,
          isDeleted: importantLocation?.IsDeleted,
          lastModified: importantLocation?.LastModifiedDate,
          recordType: importantLocation?.RecordTypeId,
          status: importantLocation?.Location_Status__c
        });
      }

      // Separate and format accounts by RecordTypeId
      const clients = response.data.records
        .filter(record => record.RecordTypeId === '0125g0000004aLaAAI')
        .map(record => ({
          accountId: record.Id,
          clientName: record.Name,
          industry: record.Industry_Sectors__c || 'N/A',
          location: record.Address__c || 'N/A',
          owner: {
            name: record.Owner?.Name || 'N/A',
            photoUrl: record.Owner?.MediumPhotoUrl || '',
            email: record.Owner?.Email || 'N/A'
          },
          opportunities: record.Opportunities?.records || [],
          annualRevenue: record.AnnualRevenue || 0,
          phone: record.Phone || 'N/A',
          website: record.Website || 'N/A',
          lastSyncedAt: new Date().toISOString()
        }));

      const locations = response.data.records
        .filter(record => record.RecordTypeId === '0125g0000004aLfAAI')
        .map(record => ({
          accountId: record.Id,
          name: record.Name,
          status: record.Location_Status__c,
          address: record.Address__c || 'N/A',
          design: record.Design__c || 'N/A',
          locationTier: record.Location_Tier__c || 'N/A',
          lastSyncedAt: new Date().toISOString(),
          potentialLocations: record.Potential_Locations__r?.records || []
        }));
      
      log('QUERY', 'Successfully fetched accounts', {
        requestedIds: ids.length,
        receivedRecords: response.data.records.length,
        clientsCount: clients.length,
        locationsCount: locations.length,
        missingRecords: ids.length - response.data.records.length
      });

      return { clients, locations };
    } catch (error) {
      logError('QUERY', error, {
        context: 'getAccountsByIds',
        idCount: ids.length
      });
      throw error;
    }
  }

  async batchQuery() {
    try {
      this.retryCount = 0;
      log('BATCH', 'Starting batch query process');

      // First get all account IDs
      log('BATCH', 'Fetching all account IDs...');
      const allIds = await this.getAllAccountIds();
      log('BATCH', `Total accounts found: ${allIds.length}`);

      // Split IDs into chunks
      const chunks = [];
      for (let i = 0; i < allIds.length; i += BATCH_SIZE) {
        chunks.push(allIds.slice(i, i + BATCH_SIZE));
      }
      log('BATCH', `Split into ${chunks.length} chunks of ${BATCH_SIZE}`);

      // Process each chunk
      let allClients = [];
      let allLocations = [];
      
      for (let i = 0; i < chunks.length; i++) {
        log('BATCH', `Processing chunk ${i + 1} of ${chunks.length}`);
        
        try {
          const { clients, locations } = await this.getAccountsByIds(chunks[i]);
          allClients = allClients.concat(clients);
          allLocations = allLocations.concat(locations);
          log('BATCH', `Progress: ${allClients.length + allLocations.length}/${allIds.length} accounts processed`);

          // Add a delay between chunks to prevent rate limiting
          if (i < chunks.length - 1) {
            await this.sleep(QUERY_DELAY);
          }
        } catch (error) {
          logError('BATCH', error, {
            context: 'Processing chunk',
            chunkIndex: i,
            chunkSize: chunks[i].length
          });
          
          // Retry logic for chunk processing
          if (this.retryCount < this.maxRetries) {
            this.retryCount++;
            log('BATCH', `Retrying chunk ${i + 1} (Attempt ${this.retryCount} of ${this.maxRetries})`);
            i--; // Retry the same chunk
            await this.sleep(this.retryDelay * this.retryCount);
            continue;
          }

          // If we've already got some records, return those rather than failing completely
          if (allClients.length > 0 || allLocations.length > 0) {
            log('BATCH', `Returning partial results due to error`, {
              processedClients: allClients.length,
              processedLocations: allLocations.length
            });
            return { clients: allClients, locations: allLocations };
          }
          throw error;
        }
      }

      log('BATCH', 'Completed processing all accounts', {
        totalClients: allClients.length,
        totalLocations: allLocations.length
      });

      return { clients: allClients, locations: allLocations };
    } catch (error) {
      logError('BATCH', error, { context: 'batchQuery' });
      throw error;
    }
  }

  isAuthenticated() {
    return !!this.accessToken && !!this.instanceUrl;
  }
}

export default new SalesforceService(); 