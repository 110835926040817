import { openDB } from 'idb';

const DB_NAME = 'purelytics';
const DB_VERSION = 2;

class IndexedDBService {
  constructor() {
    this.dbPromise = openDB(DB_NAME, DB_VERSION, {
      upgrade(db, oldVersion, newVersion) {
        // Delete old stores if they exist
        if (oldVersion < 2) {
          if (db.objectStoreNames.contains('clients')) {
            db.deleteObjectStore('clients');
          }
        }

        // Create new stores
        if (!db.objectStoreNames.contains('clients')) {
          const clientStore = db.createObjectStore('clients', { keyPath: 'accountId' });
          clientStore.createIndex('clientName', 'clientName');
          clientStore.createIndex('lastSyncedAt', 'lastSyncedAt');
        }

        if (!db.objectStoreNames.contains('locations')) {
          const locationStore = db.createObjectStore('locations', { keyPath: 'accountId' });
          locationStore.createIndex('name', 'name');
          locationStore.createIndex('lastSyncedAt', 'lastSyncedAt');
        }
      }
    });
  }

  async getDb() {
    return await this.dbPromise;
  }

  async getAllClients() {
    const db = await this.getDb();
    return db.getAll('clients');
  }

  async getClientById(id) {
    const db = await this.getDb();
    return db.get('clients', id);
  }

  async searchClients(query) {
    const clients = await this.getAllClients();
    query = query.toLowerCase();
    
    return clients.filter(client => 
      client.clientName.toLowerCase().includes(query) ||
      client.location.toLowerCase().includes(query)
    );
  }

  async getTopClients(limit = 10) {
    const clients = await this.getAllClients();
    return clients
      .sort((a, b) => {
        const aRevenue = a.opportunities.reduce((sum, opp) => 
          sum + (Number(opp.Total_Hire_Fees__c) || 0), 0);
        const bRevenue = b.opportunities.reduce((sum, opp) => 
          sum + (Number(opp.Total_Hire_Fees__c) || 0), 0);
        return bRevenue - aRevenue;
      })
      .slice(0, limit);
  }

  async getRecentClients(days = 90) {
    const clients = await this.getAllClients();
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - days);

    return clients.filter(client => {
      const lastOpp = client.opportunities[0];
      if (!lastOpp) return false;
      return new Date(lastOpp.CloseDate) >= cutoffDate;
    });
  }

  async getClientMetrics() {
    const clients = await this.getAllClients();
    
    const totalRevenue = clients.reduce((sum, client) => 
      sum + client.opportunities.reduce((oppSum, opp) => 
        oppSum + (Number(opp.Total_Hire_Fees__c) || 0), 0), 0);

    const totalProfit = clients.reduce((sum, client) => 
      sum + client.opportunities.reduce((oppSum, opp) => 
        oppSum + (Number(opp.Net_Profit__c) || 0), 0), 0);

    return {
      totalClients: clients.length,
      totalRevenue,
      totalProfit,
      averageRevenuePerClient: totalRevenue / clients.length
    };
  }

  async saveClient(client) {
    const db = await this.getDb();
    const tx = db.transaction('clients', 'readwrite');
    await tx.store.put(client);
    await tx.done;
  }

  async saveClients(clients) {
    console.log('Saving clients to IndexedDB:', {
      count: clients?.length || 0,
      sampleClient: clients?.[0]
    });
    const db = await this.getDb();
    const tx = db.transaction('clients', 'readwrite');
    await Promise.all((clients || []).map(client => tx.store.put(client)));
    await tx.done;
    console.log('Clients saved successfully');
  }

  async saveLocation(location) {
    const db = await this.getDb();
    const tx = db.transaction('locations', 'readwrite');
    await tx.store.put(location);
    await tx.done;
  }

  async saveLocations(locations) {
    console.log('Saving locations to IndexedDB:', {
      count: locations?.length || 0,
      sampleLocation: locations?.[0]
    });
    const db = await this.getDb();
    const tx = db.transaction('locations', 'readwrite');
    await Promise.all((locations || []).map(location => tx.store.put(location)));
    await tx.done;
    console.log('Locations saved successfully');
  }

  async getAllLocations() {
    const db = await this.getDb();
    return db.getAll('locations');
  }

  async clearClients() {
    console.log('Clearing clients store...');
    const db = await this.getDb();
    const tx = db.transaction('clients', 'readwrite');
    await tx.store.clear();
    await tx.done;
    console.log('Clients store cleared');
  }

  async clearLocations() {
    console.log('Clearing locations store...');
    const db = await this.getDb();
    const tx = db.transaction('locations', 'readwrite');
    await tx.store.clear();
    await tx.done;
    console.log('Locations store cleared');
  }
}

export default new IndexedDBService(); 