import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Breadcrumbs,
  Link,
  Chip,
  Avatar,
  CircularProgress,
  Backdrop
} from '@mui/material';
import { ArrowBack as ArrowBackIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import geocodingService from '../services/geocodingService';

// Fix for default marker icons in react-leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Add this component to handle bounds fitting
const MapBoundsHandler = ({ locations }) => {
  const map = useMap();

  useEffect(() => {
    if (locations.length > 0) {
      const bounds = L.latLngBounds(locations.map(loc => loc.coordinates));
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [locations, map]);

  return null;
};

// Patagonia color scheme
const pieChartColors = [
  '#0468BF',  // Primary Blue
  '#04BF55',  // Primary Green
  '#F2D027',  // Primary Yellow
  '#F28705',  // Primary Orange
  '#D92B04',  // Primary Red
  '#0357A3',  // Darker Blue
  '#03A348',  // Darker Green
  '#D9BB22',  // Darker Yellow
  '#D97904',  // Darker Orange
  '#BF2604',  // Darker Red
];

const ClientDetails = () => {
  const { clientId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const client = location.state?.client;
  const [locationData, setLocationData] = useState([]);
  const [mapCenter, setMapCenter] = useState([-33.8688, 151.2093]); // Default to Sydney
  const [mapZoom, setMapZoom] = useState(10);
  const [isPlottingLocations, setIsPlottingLocations] = useState(true);

  useEffect(() => {
    if (!client) return;

    const loadLocationData = async () => {
      setIsPlottingLocations(true);
      const locations = new Map();
      
      // First pass: collect all locations
      client.opportunities?.forEach(opp => {
        if (!opp.Location_Name__r?.Address__c) return;
        
        const locationKey = opp.Location_Name__r.Name;
        if (!locations.has(locationKey)) {
          locations.set(locationKey, {
            name: locationKey,
            address: opp.Location_Name__r.Address__c,
            bookings: [],
            coordinates: null
          });
        }
        
        locations.get(locationKey).bookings.push({
          date: new Date(opp.CloseDate).toLocaleDateString(),
          amount: Number(opp.Total_Hire_Fees__c) || 0,
          enquiryName: opp.Name
        });
      });

      // Second pass: geocode addresses
      const locationArray = Array.from(locations.values());
      const geocodedLocations = await Promise.all(
        locationArray.map(async (location) => {
          try {
            const coordinates = await geocodingService.geocodeAddress(location.address);
            return {
              ...location,
              coordinates: coordinates || [-33.8688, 151.2093] // Default to Sydney if geocoding fails
            };
          } catch (error) {
            console.error('Geocoding error:', error);
            return {
              ...location,
              coordinates: [-33.8688, 151.2093] // Default to Sydney
            };
          }
        })
      );

      // Remove the manual center/zoom calculation since we'll use bounds
      setLocationData(geocodedLocations);
      setIsPlottingLocations(false);
    };

    loadLocationData();
  }, [client]);

  if (!client) {
    return <Typography>Client not found</Typography>;
  }

  // Transform opportunities into booking history rows
  const bookingRows = client.opportunities?.map(opp => ({
    id: opp.Id || Math.random().toString(),
    date: new Date(opp.CloseDate).toLocaleDateString(),
    rawDate: new Date(opp.CloseDate), // Add rawDate for sorting
    enquiryName: opp.Name || 'N/A',
    brand: opp.Brand__c || 'N/A',
    designStyle: opp.Location_Name__r?.Design__c || 'Not Specified',
    location: opp.Location_Name__r?.Name || 'N/A',
    type: opp.Type || 'Shoot',
    amount: Number(opp.Total_Hire_Fees__c) || 0,
    grossProfit: Number(opp.Gross_Profit__c) || 0,
  })) || [];

  // Let's also add some console.logs to debug the data
  console.log('First opportunity:', client.opportunities?.[0]);
  console.log('Location data:', client.opportunities?.[0]?.Location_Name__r);

  // Booking history columns
  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      sortable: true,
      sortComparator: (v1, v2, param1, param2) => {
        const date1 = param1?.row?.rawDate ? param1.row.rawDate.getTime() : 0;
        const date2 = param2?.row?.rawDate ? param2.row.rawDate.getTime() : 0;
        return date2 - date1;
      }
    },
    {
      field: 'enquiryName',
      headerName: 'Enquiry Name',
      flex: 2,
    },
    {
      field: 'brand',
      headerName: 'Brand',
      flex: 1,
    },
    {
      field: 'designStyle',
      headerName: 'Design Style',
      flex: 1,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
    },
   
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1,
      type: 'number',
      valueFormatter: (params) => `$${Number(params).toLocaleString()}`,
    },
    {
      field: 'grossProfit',
      headerName: 'Gross Profit',
      flex: 1,
      type: 'number',
      valueFormatter: (params) => `$${Number(params).toLocaleString()}`,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      renderCell: (params) => (
        <IconButton 
          size="small"
          onClick={() => {
            const sfBaseUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://purelocations.lightning.force.com';
            const oppId = client.opportunities.find(opp => opp.Name === params.row.enquiryName)?.Id;
            if (oppId) {
              window.open(`${sfBaseUrl}/lightning/r/Opportunity/${oppId}/view`, '_blank');
            }
          }}
          title="Open in Salesforce"
        >
          <OpenInNewIcon fontSize="small" />
        </IconButton>
      ),
    },
  ];

  // Transform opportunities data for the chart
  const getChartData = () => {
    // Initialize array with all months
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    
    // Create initial data structure with all months
    const monthlyData = new Map(
      months.map(month => [month, {
        month,
        revenue: 0,
        bookings: 0
      }])
    );

    // Process opportunities
    client.opportunities?.forEach(opp => {
      if (!opp.CloseDate) return;

      const date = new Date(opp.CloseDate);
      const monthKey = months[date.getMonth()]; // Get month name
      
      const data = monthlyData.get(monthKey);
      data.revenue += Number(opp.Total_Hire_Fees__c) || 0;
      data.bookings += 1;
    });

    // Convert to array and ensure month order
    return months.map(month => monthlyData.get(month));
  };

  const chartData = {
    dataset: getChartData()
  };

  // Sample data for pie charts
  const bookingPatterns = [
    { id: 0, value: 32.4, label: 'Jun' },
    { id: 1, value: 15.2, label: 'Jul' },
    { id: 2, value: 19.1, label: 'Aug' },
    { id: 3, value: 10.8, label: 'Sep' },
    { id: 4, value: 12.5, label: 'Oct' },
    { id: 5, value: 10.0, label: 'Nov' },
  ];

  // Transform opportunities data for design styles chart
  const getDesignStylesData = () => {
    // Create a map to count design styles
    const designStyleCounts = new Map();
    let totalStyles = 0;

    client.opportunities?.forEach(opp => {
      const designString = opp.Location_Name__r?.Design__c || 'Not Specified';
      
      // Split the design string into individual styles
      const styles = designString.split(';').map(s => s.trim());
      
      // Count each individual style
      styles.forEach(style => {
        if (!designStyleCounts.has(style)) {
          designStyleCounts.set(style, 0);
        }
        designStyleCounts.set(style, designStyleCounts.get(style) + 1);
        totalStyles++;
      });
    });

    // Convert to array, calculate percentages, sort, and take top 10
    return Array.from(designStyleCounts.entries())
      .map(([label, count]) => ({
        label,
        count,
        value: (count / totalStyles) * 100
      }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 10)  // Take only top 10
      .map((style, index) => ({  // Add ids
        id: index,
        label: style.label,
        value: style.value,
        count: style.count
      }));
  };

  // Replace the static designStyles with dynamic data
  const designStyles = getDesignStylesData();

  // Get preferred month (month with most bookings)
  const getPreferredMonth = () => {
    const monthlyBookings = getChartData();
    return monthlyBookings.reduce((max, current) => 
      current.bookings > max.bookings ? current : max
    ).month;
  };

  // Get preferred style (style with highest percentage)
  const getPreferredStyle = () => {
    const styles = getDesignStylesData();
    return styles.length > 0 ? styles[0].label : 'Not Specified';  // First item is highest due to sort
  };

  const preferredMonth = getPreferredMonth();
  const preferredStyle = getPreferredStyle();

  return (
    <Box>
      {/* Header */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <IconButton onClick={() => navigate('/clients')} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" sx={{ flex: 1 }}>{client.clientName}</Typography>
          <IconButton 
            onClick={() => {
              const sfBaseUrl = process.env.REACT_APP_SALESFORCE_URL || 'https://purelocations.lightning.force.com';
              window.open(`${sfBaseUrl}/lightning/r/Account/${client.accountId}/view`, '_blank');
            }}
            title="Open in Salesforce"
          >
            <OpenInNewIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar src={client.owner?.photoUrl}>
              {client.owner?.name?.charAt(0) || 'N/A'}
            </Avatar>
            <Box>
              <Typography variant="body2" color="text.secondary">Account Owner</Typography>
              <Typography>{client.owner?.name}</Typography>
            </Box>
          </Box>
          <Chip 
            label={`Preferred Month: ${preferredMonth}`} 
            sx={{ backgroundColor: '#0468BF', color: 'white' }} 
            variant="solid" 
          />
          <Chip 
            label={`Preferred Style: ${preferredStyle}`} 
            sx={{ backgroundColor: '#03A348', color: 'white' }} 
            variant="solid" 
          />
        </Box>
      </Box>

      {/* Content */}
      <Grid container spacing={3}>

{/* Revenue Chart - 50% width */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Lifetime Revenue and Bookings Trend</Typography>
              <Box sx={{ width: '100%', height: 400 }}>
                <LineChart
                  dataset={chartData.dataset}
                  margin={{ top: 20, right: 40, bottom: 80, left: 70 }}
                  grid={{ vertical: true, horizontal: true }}
                  xAxis={[{ 
                    dataKey: 'month',
                    scaleType: 'band',
                  }]}
                  yAxis={[
                    { 
                      id: 'revenue',
                      scaleType: 'linear',
                      valueFormatter: (value) => `$${value.toLocaleString()}`,
                      tickNumber: 6,
                      tickSize: 10,
                      labelStyle: {
                        fontSize: 12,
                        dx: -10,
                      },
                    },
                    { 
                      id: 'bookings',
                      scaleType: 'linear',
                      position: 'right',
                      tickNumber: 6,
                      tickSize: 10,
                    }
                  ]}
                  series={[
                    {
                      dataKey: 'revenue',
                      label: 'Monthly Revenue',
                      yAxisKey: 'revenue',
                      color: '#8884d8',
                      showMark: false,
                    },
                    {
                      dataKey: 'bookings',
                      label: 'Number of Bookings',
                      yAxisKey: 'bookings',
                      color: '#82ca9d',
                      showMark: false,
                    },
                  ]}
                  height={350}
                  slotProps={{
                    legend: {
                      direction: 'row',
                      position: { vertical: 'bottom', horizontal: 'middle' },
                      padding: { top: 20, bottom: 0, left: 0, right: 0 },
                      itemMarkWidth: 10,
                      itemMarkHeight: 10,
                      markGap: 5,
                      itemGap: 15,
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Design Styles - 50% width */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Favourite Design Styles</Typography>
              <Box sx={{ width: '100%', height: 400 }}>
                <PieChart
                  series={[
                    {
                      data: designStyles,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      faded: { innerRadius: 10, additionalRadius: -10 },
                      innerRadius: 10,
                      paddingAngle: 0,
                      cornerRadius: 2,
                     
                    },
                  ]}
                  colors={pieChartColors}
                  height={350}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'middle', horizontal: 'right' },
                      padding: 20,
                      itemMarkWidth: 8,
                      itemMarkHeight: 8,
                      markGap: 5,
                      itemGap: 8,
                      labelStyle: {
                        fontSize: 12,
                      },
                    },
                  }}
                  margin={{ top: 20, right: 160, bottom: 50, left: 50 }}
                  tooltip={{
                    trigger: 'item',
                    formatter: (params) => `${params.label}: ${params.value.toFixed(1)}%`
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>


        {/* Booking History - Full width */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Booking History</Typography>
              <Box sx={{ height: 400 }}>
                <DataGridPro
                  rows={bookingRows}
                  columns={columns}
                  density="comfortable"
                  pagination
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                    pagination: {
                      pageSize: 10,
                    },
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>

        
        {/* Add Map Section */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Booking Locations</Typography>
              <Box sx={{ width: '100%', height: 400, position: 'relative' }}>
                <MapContainer 
                  center={[-33.8688, 151.2093]}  // Default center until bounds are calculated
                  zoom={4}  // Default zoom until bounds are calculated
                  style={{ height: '100%', width: '100%' }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <MarkerClusterGroup
                    chunkedLoading
                    maxClusterRadius={50}
                  >
                    {locationData.map((location, index) => (
                      <Marker 
                        key={index} 
                        position={location.coordinates}
                      >
                        <Popup>
                          <Typography variant="subtitle2">{location.name}</Typography>
                          <Typography variant="body2">{location.address}</Typography>
                          <Typography variant="caption">
                            Bookings: {location.bookings.length}
                          </Typography>
                          <Box sx={{ mt: 1 }}>
                            {location.bookings.map((booking, i) => (
                              <Typography key={i} variant="caption" display="block">
                                {booking.date}: ${booking.amount.toLocaleString()}
                              </Typography>
                            ))}
                          </Box>
                        </Popup>
                      </Marker>
                    ))}
                  </MarkerClusterGroup>
                  <MapBoundsHandler locations={locationData} />
                </MapContainer>
                <Backdrop
                  open={isPlottingLocations}
                  sx={{
                    position: 'absolute',
                    color: '#fff',
                    zIndex: 1000,
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1 }}>
                    <CircularProgress color="inherit" />
                    <Typography>Plotting Locations...</Typography>
                  </Box>
                </Backdrop>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClientDetails; 