import React, { useState, useCallback, useEffect } from 'react';
import { Box, AppBar, Toolbar, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Divider, Button, FormControl, Select, MenuItem, Backdrop, CircularProgress } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, People as PeopleIcon, LocationOn as LocationIcon, Refresh as RefreshIcon, AutoAwesome as SparkleIcon } from '@mui/icons-material';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import dataSyncService from '../services/dataSyncService';

const drawerWidth = 240;

const DashboardLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [startDate, setStartDate] = useState(() => {
    const savedStartDate = localStorage.getItem('globalStartDate');
    return savedStartDate ? dayjs(savedStartDate) : dayjs().subtract(365, 'day');
  });
  const [endDate, setEndDate] = useState(() => {
    const savedEndDate = localStorage.getItem('globalEndDate');
    return savedEndDate ? dayjs(savedEndDate) : dayjs();
  });
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [syncingData, setSyncingData] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Save dates to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('globalStartDate', startDate.toISOString());
    localStorage.setItem('globalEndDate', endDate.toISOString());
  }, [startDate, endDate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleRefreshData = async () => {
    setSyncingData(true);
    try {
      await dataSyncService.syncData();
      // Dispatch a custom event that child components can listen to
      window.dispatchEvent(new CustomEvent('globalDataRefresh', {
        detail: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString()
        }
      }));
      // Reload the page after successful sync
      window.location.reload();
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setSyncingData(false);
    }
  };

  const handleDateChange = useCallback((type, newValue) => {
    if (type === 'start') {
      setStartDate(newValue);
    } else {
      setEndDate(newValue);
    }
    // Dispatch date change event
    window.dispatchEvent(new CustomEvent('globalDateChange', {
      detail: {
        startDate: type === 'start' ? newValue.toISOString() : startDate.toISOString(),
        endDate: type === 'end' ? newValue.toISOString() : endDate.toISOString()
      }
    }));
  }, [startDate, endDate]);

  const handleQuickRangeChange = (e) => {
    const range = e.target.value;
    let newStartDate = dayjs();
    const newEndDate = dayjs();

    switch (range) {
      case '30':
        newStartDate = newEndDate.subtract(30, 'day');
        break;
      case '90':
        newStartDate = newEndDate.subtract(90, 'day');
        break;
      case '180':
        newStartDate = newEndDate.subtract(180, 'day');
        break;
      case '365':
        newStartDate = newEndDate.subtract(365, 'day');
        break;
      case 'all':
        newStartDate = dayjs('1970-01-01');
        break;
      default:
        newStartDate = newEndDate.subtract(365, 'day');
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);

    // Save to localStorage
    localStorage.setItem('globalStartDate', newStartDate.toISOString());
    localStorage.setItem('globalEndDate', newEndDate.toISOString());

    window.dispatchEvent(new CustomEvent('globalDateChange', {
      detail: {
        startDate: newStartDate.toISOString(),
        endDate: newEndDate.toISOString()
      }
    }));
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
    { text: 'Clients', icon: <PeopleIcon />, path: '/clients' },
    { text: 'Locations', icon: <LocationIcon />, path: '/locations' },
    { text: 'AI Insights', icon: <SparkleIcon />, path: '/insights' },
  ];

  const handleNavigation = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const isDateRangeNeeded = () => {
    const path = location.pathname;
    return !path.includes('/clients/') && !path.includes('/locations/'); // Disable on detail pages
  };

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Purelytics
        </Typography>
      </Toolbar>
      <Divider />
      <Box sx={{ p: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => handleDateChange('start', newValue)}
            slotProps={{ 
              textField: { 
                size: 'small', 
                sx: { 
                  mb: 2,
                  opacity: isDateRangeNeeded() ? 1 : 0.5,
                  pointerEvents: isDateRangeNeeded() ? 'auto' : 'none'
                } 
              } 
            }}
            format="DD/MM/YYYY"
            disabled={!isDateRangeNeeded()}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => handleDateChange('end', newValue)}
            slotProps={{ 
              textField: { 
                size: 'small', 
                sx: { 
                  mb: 2,
                  opacity: isDateRangeNeeded() ? 1 : 0.5,
                  pointerEvents: isDateRangeNeeded() ? 'auto' : 'none'
                } 
              } 
            }}
            format="DD/MM/YYYY"
            disabled={!isDateRangeNeeded()}
          />
        </LocalizationProvider>
        
        <FormControl fullWidth size="small" sx={{ 
          mb: 2,
          opacity: isDateRangeNeeded() ? 1 : 0.5,
          pointerEvents: isDateRangeNeeded() ? 'auto' : 'none'
        }}>
          <Select
            defaultValue="365"
            onChange={handleQuickRangeChange}
            disabled={!isDateRangeNeeded()}
          >
            <MenuItem value="30">Last 30 Days</MenuItem>
            <MenuItem value="90">Last 3 Months</MenuItem>
            <MenuItem value="180">Last 6 Months</MenuItem>
            <MenuItem value="365">Last Year</MenuItem>
            <MenuItem value="all">All Time</MenuItem>
          </Select>
        </FormControl>
       
        <Button
          fullWidth
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={handleRefreshData}
          disabled={isRefreshing}
          sx={{ mt: 1 }}
        >
          {isRefreshing ? 'Refreshing...' : 'Refresh All Data'}
        </Button>
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem 
            key={item.text}
            onClick={() => handleNavigation(item.path)}
            selected={location.pathname === item.path}
            sx={{ 
              '&:hover': { 
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                cursor: 'pointer'
              }
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </div>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Purelytics Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: '100%',
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      
      {/* Add loading overlay */}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 2,
          flexDirection: 'column',
          gap: 2
        }}
        open={syncingData}
      >
        <CircularProgress color="inherit" size={60} />
        <Typography variant="h6">
          Syncing Data...
        </Typography>
      </Backdrop>
    </Box>
  );
};

export default DashboardLayout; 