import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Chip,
  Divider,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Paper,
} from '@mui/material';
import {
  TrendingUp,
  LocationOn,
  Style,
  CalendarMonth,
  People,
  Lightbulb,
  Search as SearchIcon,
  QuestionAnswer as QuestionAnswerIcon,
} from '@mui/icons-material';
import openAIService from '../services/openAIService';
import indexedDBService from '../services/indexedDBService';
import dayjs from 'dayjs';

const Insights = () => {
  const [insights, setInsights] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rawData, setRawData] = useState(null);
  const [newDateRange, setNewDateRange] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    const loadInsights = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Get current date range from DashboardLayout
        const dateChangeListener = (event) => {
          const { startDate, endDate } = event.detail;
          setNewDateRange({
            startDate: new Date(startDate),
            endDate: new Date(endDate)
          });
          setShowPrompt(true);
        };

        // Listen for future date changes
        window.addEventListener('globalDateChange', dateChangeListener);

        // On first load, show the date confirmation dialog
        const defaultStartDate = dayjs().subtract(365, 'day').toDate();
        const defaultEndDate = new Date();
        
        setNewDateRange({
          startDate: defaultStartDate,
          endDate: defaultEndDate
        });
        setShowPrompt(true);
        setLoading(false);

        return () => {
          window.removeEventListener('globalDateChange', dateChangeListener);
        };
      } catch (err) {
        setError(err.message);
        console.error('Error in setup:', err);
        setLoading(false);
      }
    };

    loadInsights();
  }, []);

  const loadDataWithDates = async (startDate, endDate) => {
    try {
      setLoading(true);
      const clients = await indexedDBService.getAllClients();
      
      const summarizedData = openAIService.summarizeBookingData(
        clients,
        startDate,
        endDate
      );
      setRawData(summarizedData);

      const analysisResults = await openAIService.analyzeBookingTrends(startDate, endDate);
      if (!analysisResults) {
        throw new Error('Unable to generate insights at this time');
      }
      setInsights(analysisResults);
    } catch (err) {
      setError(err.message);
      console.error('Error loading insights:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateNewInsights = () => {
    if (newDateRange) {
      loadDataWithDates(newDateRange.startDate, newDateRange.endDate);
    }
    setShowPrompt(false);
    setNewDateRange(null);
  };

  const handleCancelNewInsights = () => {
    setShowPrompt(false);
    setNewDateRange(null);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      setIsSearching(true);
      setSearchError(null);
      
      const clients = await indexedDBService.getAllClients();
      const startDate = newDateRange?.startDate || dayjs().subtract(365, 'day').toDate();
      const endDate = newDateRange?.endDate || new Date();
      
      const results = await openAIService.queryBookingData(
        searchQuery,
        clients,
        startDate,
        endDate
      );
      
      setSearchResults(results);
    } catch (err) {
      setSearchError(err.message);
      console.error('Error searching:', err);
    } finally {
      setIsSearching(false);
    }
  };

  const handleManualGenerateInsights = () => {
    if (newDateRange) {
      loadDataWithDates(newDateRange.startDate, newDateRange.endDate);
    } else {
      // Use default date range if none is set
      const defaultStartDate = dayjs().subtract(365, 'day').toDate();
      const defaultEndDate = new Date();
      loadDataWithDates(defaultStartDate, defaultEndDate);
    }
  };

  // Search Results Component
  const SearchResults = () => {
    if (!searchResults) return null;

    return (
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <QuestionAnswerIcon color="primary" sx={{ mr: 1 }} />
              <Typography variant="h6">Answer</Typography>
            </Box>
            
            <Paper 
              elevation={0} 
              sx={{ 
                p: 2, 
                mb: 2, 
                bgcolor: 'primary.light', 
                color: 'primary.contrastText' 
              }}
            >
              <Typography variant="body1" component="div" sx={{ whiteSpace: 'pre-wrap' }}>
                Q: {searchQuery}
              </Typography>
            </Paper>

            <Paper 
              elevation={1} 
              sx={{ 
                p: 2,
                bgcolor: 'background.paper',
                border: 1,
                borderColor: 'divider'
              }}
            >
              <Typography 
                variant="body1" 
                component="div" 
                sx={{ 
                  whiteSpace: 'pre-wrap',
                  '& strong': { color: 'primary.main' }
                }}
              >
                {searchResults.answer}
              </Typography>
            </Paper>

            {/* Show relevant metrics */}
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Based on data from {new Date(searchResults.data.overview.dateRange.start).toLocaleDateString()} 
                to {new Date(searchResults.data.overview.dateRange.end).toLocaleDateString()}
              </Typography>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={4}>
                  <Typography variant="caption" color="text.secondary">Total Bookings</Typography>
                  <Typography variant="h6">{searchResults.data.overview.totalBookings.toLocaleString()}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" color="text.secondary">Total Revenue</Typography>
                  <Typography variant="h6">${searchResults.data.overview.totalRevenue.toLocaleString()}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" color="text.secondary">Avg. Booking Value</Typography>
                  <Typography variant="h6">
                    ${searchResults.data.overview.averageBookingValue.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  // Update the DateRangePrompt component
  const DateRangePrompt = () => (
    <Dialog open={showPrompt} onClose={handleCancelNewInsights}>
      <DialogTitle>Generate New Insights?</DialogTitle>
      <DialogContent>
        <Box>
          <Typography gutterBottom>
            Would you like to generate new AI insights for the selected date range?
          </Typography>
          {newDateRange && (
            <Box sx={{ mt: 1 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                From: {newDateRange.startDate.toLocaleDateString()}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                To: {newDateRange.endDate.toLocaleDateString()}
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelNewInsights}>Cancel</Button>
        <Button 
          onClick={handleGenerateNewInsights} 
          variant="contained" 
          color="primary"
        >
          Generate Insights
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 3 }}>
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        mb: 3 
      }}>
        <Typography variant="h5">
          AI-Powered Insights
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleManualGenerateInsights}
          startIcon={<Lightbulb />}
          disabled={loading}
        >
          Generate Insights
        </Button>
      </Box>

      {/* Search Form */}
      <Paper 
        component="form" 
        onSubmit={handleSearch}
        sx={{ 
          p: 2,
          mb: 3,
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Ask a question about your booking data (e.g., 'What are our top performing locations?' or 'How has revenue changed over time?')"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          disabled={isSearching}
          sx={{ flex: 1 }}
        />
        <IconButton 
          type="submit" 
          color="primary" 
          disabled={isSearching || !searchQuery.trim()}
          sx={{ p: '10px' }}
        >
          {isSearching ? <CircularProgress size={24} /> : <SearchIcon />}
        </IconButton>
      </Paper>

      {/* Search Error */}
      {searchError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {searchError}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Search Results */}
        {searchResults && <SearchResults />}

        {/* Overview Stats */}
        {rawData && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" color="primary">
                      Total Bookings
                    </Typography>
                    <Typography variant="h4">
                      {rawData.overview.totalBookings.toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" color="primary">
                      Total Revenue
                    </Typography>
                    <Typography variant="h4">
                      ${rawData.overview.totalRevenue.toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" color="primary">
                      Average Booking Value
                    </Typography>
                    <Typography variant="h4">
                      ${rawData.overview.averageBookingValue.toLocaleString(undefined, { maximumFractionDigits: 0 })}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* Only show insights cards when data is available */}
        {insights && (
          <>
            {/* Key Insights */}
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Lightbulb color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6">Key Insights</Typography>
                  </Box>
                  <List>
                    {insights?.keyInsights?.map((insight, index) => (
                      <ListItem key={index}>
                        <ListItemIcon>
                          <TrendingUp color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={insight} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}

        {/* Revenue Trends */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TrendingUp color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Revenue Trends</Typography>
              </Box>
              <Typography paragraph>{insights?.revenueTrends?.analysis || 'No revenue trends available'}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography color="primary">{insights?.revenueTrends?.prediction}</Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Location Insights */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <LocationOn color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Location & Design Insights</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>Top Locations</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {insights?.locationInsights?.topLocations?.map((location, index) => (
                    <Chip 
                      key={index}
                      label={location}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Box>
              <Typography paragraph>{insights?.locationInsights?.designTrends}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography color="primary" paragraph>
                {insights?.locationInsights?.recommendations}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Seasonal Patterns */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CalendarMonth color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Seasonal Patterns</Typography>
              </Box>
              <Typography paragraph>{insights?.seasonalPatterns?.peakPeriods || 'No seasonal patterns available'}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography color="primary">
                {insights?.seasonalPatterns?.recommendations}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Client Insights */}
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <People color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6">Client Insights</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>Key Clients</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                  {insights?.clientInsights?.topClients?.map((client, index) => (
                    <Chip 
                      key={index}
                      label={client}
                      color="primary"
                      variant="outlined"
                    />
                  ))}
                </Box>
              </Box>
              <Typography paragraph>{insights?.clientInsights?.patterns}</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography color="primary">
                {insights?.clientInsights?.recommendations}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Insights; 