import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import Dashboard from './pages/Dashboard';
import Clients from './pages/Clients';
import ClientDetails from './pages/ClientDetails';
import Locations from './pages/Locations';
import LocationDetails from './pages/LocationDetails';
import Insights from './pages/Insights';
import './index.css';

function App({ onLogout }) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DashboardLayout onLogout={onLogout} />}>
          <Route index element={<Dashboard />} />
          <Route path="clients" element={<Clients />} />
          <Route path="clients/:clientId" element={<ClientDetails />} />
          <Route path="locations" element={<Locations />} />
          <Route path="locations/:locationId" element={<LocationDetails />} />
          <Route path="insights" element={<Insights />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
